<template>
  <CRow>
    <CCol col="12">
      <CModal :show.sync="op_confirm.show" :centered="true" :title="$t(op_confirm.title) + ` [ID: ${op_confirm.value.id}]`" size="lg">
        {{ $t(op_confirm.message) }}
        <template #footer>
          <CButton type="button" class="mr-2" :color="op_confirm.color_yes || 'primary'" @click="onModalConfirmed(op_confirm.response_yes || true)">{{ $t(op_confirm.yes || 'Yes') }}</CButton>
          <CButton v-if="op_confirm.no" type="button" :color="op_confirm.color_no || 'default'" @click="onModalConfirmed(op_confirm.response_no || false)">{{ $t(op_confirm.no || 'No') }}</CButton>
          <CButton v-if="op_confirm.cancel !== false" type="button" :color="op_confirm.color_cancel || 'secondary'" @click="onModalConfirmed(op_confirm.response_cancel)">{{ $t('Cancel') }}</CButton>
        </template>
      </CModal>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit invoice note') + ` id: ${form.id}` : $t('Create invoice note') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard>
              <AMultiSelect :readonly="is_disabled" :disabled="true" :horizontal="{ input: 'col-sm-4' }" name="month_id" label="Month" v-model="form.month_id" :options="lists.months" :isValid="isValid('month_id')" :errors="getErrors('month_id')" />
              <AMultiSelect :readonly="is_disabled" :horizontal="{ input: 'col-sm-6' }" name="nature_id" label="Nature" v-model="form.nature_id" :options="lists.natures" :isValid="isValid('nature_id')" :errors="getErrors('nature_id')" />
              <AMultiSelect :readonly="is_disabled" :horizontal="{ input: 'col-sm-6' }" name="imputation_id" label="Imputation" v-model="form.imputation_id" :options="lists.imputations" :isValid="isValid('imputation_id')" :errors="getErrors('imputation_id')" />
              <AMultiSelect :readonly="is_disabled" :horizontal="{ input: 'col-sm-6' }" name="class_id" label="Class" v-model="form.class_id" :options="lists.classes" :isValid="isValid('class_id')" :errors="getErrors('class_id')" />
              <AInput :readonly="is_disabled" label="Name" name="name" v-model="form.name" :isValid="isValid('name')" :errors="getErrors('name')" />
              <AInput :readonly="is_disabled" :horizontal="{ input: 'col-sm-6' }" label="Descriptor" name="descriptor" v-model="form.descriptor" :isValid="isValid('descriptor')" :errors="getErrors('descriptor')" />
              <AMultiSelect :readonly="is_disabled" :horizontal="{ input: 'col-sm-6' }" name="building_id" label="Building" v-model="form.building_id" :options="lists.buildings" :isValid="isValid('building_id')" :errors="getErrors('building_id')" />
              <AMaskedInput :readonly="is_disabled" :horizontal="{ input: 'col-sm-3' }" label="Value" name="value" v-model="form.value" mask="value" maxLength="15" :isValid="isValid('value')" :errors="getErrors('value')" />
              <ATextArea :readonly="is_disabled" label="Observations" v-model="form.description" />
            </ACard>
          </CForm>

          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" :disabled="is_disabled" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'InvoiceNoteForm',
  mixins: [formMixin],
  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0 },

      // Helpers
      is_disabled: false,
      available_months: [],
      selected_months: [],
      selected_buildings: [],
      undeletable_months: [],

      lists: {},
      alerts: [],
      objects: {},
      validators: {},

      op_confirm: { show: false, title: 'Remove row', message: 'Are you sure you want to delete this row?', value: {} }
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/property_invoice_notes' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      //this.form = data.property_invoice_note ? data.property_invoice_note : { id: 0 }
      this.$set(this, 'form', { ...(data.property_invoice_note ? data.property_invoice_note : { id: 0 }) })

      this.is_principal = !!this.form.is_principal
      this.lists = data._lists || {}

      this.parseView()
      this.setObjects()
      this.parsedBuildings()

      this.validators = {
        main: data._validation || {},
        extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)
    },

    // Helpers
    setObjects() {
      this.objects.building_types = {}
      for (const building_type of this.lists.building_types || []) {
        this.objects.building_types[building_type.id] = building_type
      }
      this.objects.property_units = {}
      for (const property_unit of this.lists.property_units || []) {
        this.objects.property_units[property_unit.id] = property_unit
      }
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create'), disabled: this.is_disabled }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'InvoiceNote']: options })
    },
    parsedBuildings() {
      this.lists.buildings.forEach(item => {
        item.name = `(${this.objects.property_units[item.property_unit_id].name}) ${this.objects.building_types[item.building_type_id].name} - ${item.code}`
      })
    },

    // SUBMIT

    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      const self = this
      self.form = self.stripNumbers(self.form, ['value'])
      self.$http[self.form.id ? 'put' : 'post']('admin/property_invoice_notes', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/processes/invoice_notes/${response.data.property_invoice_note.id}/edit`).catch(() => {})
          self.showAlert(`Invoice note ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the invoice note.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
